import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const About = ({location}) => (
  <Layout location={location}>
    <Seo title="About us" />
    <div className="has-text-centered p-4">
      <h1 className="title">K & B</h1>
      <p>K&B is a non Periodical guide specialist in Kitchen & Bathroom Published by Light Point Marketing & Advertising Agency. All rights reserved. No parts of this Guide, including graphics, ideas or photographs can be reproduced or transmitted in any from or
      by any means, electronic or mechanical, including photocopying, recording or by any information storage or retrieval system, without permission from the Publisher in writing.
      </p>
      <div>
        published by
        <div>
        <StaticImage
            src="../img/lightpoint-logo.jpg"
            width={300}
            alt="Light Point"
            style={{ marginBottom: `1.45rem` }}
          />
        </div>
      </div>
    </div>
  </Layout>
)

export default About
